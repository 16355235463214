import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ResetPasswordPage.css'; // Add your CSS for styling
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';

const ResetPasswordPage = () => {
  const { token } = useParams(); // Extract token from URL params
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/reset-password/${token}`, { password });
      setSuccessMessage('Password reset successfully');
      setError('');

      // Redirect to login page after a delay
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      setError('Failed to reset password. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className='reset-password-main'>
      <Navbar />
      <div className="reset-password-container">
        <h2>Reset Password</h2>
        <p>Please enter your new password</p>
        {error && <p className="error">{error}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              id="password"
              placeholder='New Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordPage;
