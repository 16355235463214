import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './OrderConfirmation.css';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import OrderSuccessPage from '../pages/OrderSuccessPage';
import logo from '../assets/logo.png';

const OrderConfirmation = () => {
    const location = useLocation();
    const {orderDetails} = location.state;
    const [couponCode, setCouponCode] = useState('');
    const [totalPay, setTotalPay] = useState(orderDetails.reduce((acc, product) => acc + product.price, 0));
    const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(1); // Track current step
    const [shippingDetails, setShippingDetails] = useState({
        order_id: "224-47821",
        order_date: "2024-07-01 11:11",
        pickup_location: "Delhi",
        billing_customer_name: "",
        billing_last_name: "",
        billing_address: "",
        billing_city: "",
        billing_pincode: "",
        billing_state: "none",
        billing_country: "India",
        billing_email: "",
        billing_phone: "",
        shipping_is_billing: true,
        payment_method: "Prepaid",
        sub_total: totalPay,
        length: "10",
        breadth: "15",
        height: "20",
        weight: orderDetails.weight,
        productId: orderDetails.productId
    });
    const handleApplyCoupon = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/coupon/use`, {
                price: shippingDetails.sub_total,
                couponName: couponCode
            });
            console.log(response);

            if (response.statusText) {
                setTotalPay(response.data.discountedPrice);
                alert('Coupon applied successfully!');
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error applying coupon:', error.message);
            alert('Failed to apply coupon. Please try again.');
        }
    };

    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchUserData = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/getuser`, config);
                setShippingDetails(prevDetails => ({
                    ...prevDetails,
                    billing_customer_name: response.data.firstName,
                    billing_last_name: response.data.lastName,
                    billing_address: response.data.address,
                    billing_city: response.data.city,
                    billing_pincode: response.data.pincode,
                    billing_state: response.data.state,
                    billing_email: response.data.email,
                    billing_phone: response.data.phone
                }));
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchUserData();
    }, [token]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setShippingDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!shippingDetails.billing_customer_name) newErrors.billing_customer_name = 'First Name is required';
        if (!shippingDetails.billing_last_name) newErrors.billing_last_name = 'Last Name is required';
        if (!shippingDetails.billing_address) newErrors.billing_address = 'Address is required';
        if (!shippingDetails.billing_city) newErrors.billing_city = 'City is required';
        if (!shippingDetails.billing_pincode) newErrors.billing_pincode = 'Pincode is required';
        if (!shippingDetails.billing_state) newErrors.billing_state = 'State is required';
        if (!shippingDetails.billing_email) newErrors.billing_email = 'Email is required';
        if (!shippingDetails.billing_phone) newErrors.billing_phone = 'Phone is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePayment = async () => {
        if (!validateForm()) {
            return;
        }

        try {    const deliveryCharge = calculateDeliveryCharge();
            const total_pay =totalPay+deliveryCharge;
            console.log("totttt",total_pay)
            const amountInPaisa = Math.max(total_pay, 100); // Ensure minimum amount is 1 INR (100 paisa)
         

            const razorpayresponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/createOrder`, {
                amount: amountInPaisa,
                currency: 'INR',
                name: 'Product Name', // Replace with actual product name
                description: 'Product Description' // Replace with actual product description
            });

            const { currency, amount, razorpay_signature } = razorpayresponse.data.order;
            const order_id = razorpayresponse.data.order_id;
            console.log(order_id);
            console.log(razorpayresponse.data);
            console.log(amount);
          
           

            const options = {
                key: process.env.RAZORPAY_KEY, // Replace with your Razorpay key ID
                amount: amount,
                currency: currency,
                order_id: order_id,
                razorpay_signature: razorpay_signature,
                handler: async function (response) {
                    try {
                        // Handle payment success
                        const verifyResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/verify`, {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature,
                        });
                      
                        if (verifyResponse.data.success) {
                            const token = localStorage.getItem('token');
                            console.log("Order Details:", orderDetails);
                            console.log("Shipping Details:", shippingDetails);
                            const productsData = orderDetails.map(product => ({
                                cartId:product.cartId,
                                productId: product.productId,
                                productName: product.title,
                                quantity: product.quantity,
                                weight: product.weight,
                                price: product.price,
                                productImg:product.productImg,
                            }));
                               console.log(productsData);
                            // Create the order in the database after payment is verified
                            const createOrderResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/panelOrder`, {
                               orderItems: productsData,
                                order_id: order_id,
                                total_pay:total_pay,
                                payment_id: response.razorpay_payment_id,
                                shippingAddress: {
                                    name: `${shippingDetails.billing_customer_name} ${shippingDetails.billing_last_name}`,
                                    address: shippingDetails.billing_address,
                                    city: shippingDetails.billing_city,
                                    state: shippingDetails.billing_state,
                                    country: shippingDetails.billing_country,
                                    pincode: shippingDetails.billing_pincode,
                                    email: shippingDetails.billing_email,
                                    phone: shippingDetails.billing_phone,
                                }
                            }, {
                                headers: { Authorization: `Bearer ${token}` }
                            });
                            if (createOrderResponse.data.success) {
                                alert('Payment successful and order created successfully.');
                                // Redirect or update UI as needed
                               
                                navigate('/order-success', { state: { orderDetails, shippingDetails,payment_id: response.razorpay_payment_id, } });
                            } else {
                                throw new Error('Failed to create order');
                            }
                        } else {
                            throw new Error('Payment verification failed');
                        }
                    } catch (error) {
                        console.error('Error verifying payment:', error.message);
                        alert('Payment failed. Please try again.');
                    }
                },
                prefill: {
                    name: `${shippingDetails.billing_customer_name} ${shippingDetails.billing_last_name}`,
                    email: shippingDetails.billing_email,
                    contact: shippingDetails.billing_phone
                },
                theme: {
                    color: '#723207'
                }
            };

            if (window.Razorpay) {
                const rzp1 = new window.Razorpay(options);
                rzp1.open();
            } else {
                throw new Error('Razorpay SDK not loaded');
            }
        } catch (error) {
            console.error('Error during payment:', error.message);
            alert('Payment failed. Please try again.');
        }
    };
    const renderDeliveryCharges = () => {
        const deliveryCharge = calculateDeliveryCharge();
        return (
            
            <div className="delivery-charges">
                <h3>Delivery Charges</h3>
                <p>{deliveryCharge === 0 ? 'Free Delivery' : `₹${deliveryCharge}`}</p>
            </div>
        );
    };
    const calculateDeliveryCharge = () => {
        const { billing_state, sub_total } = shippingDetails;
        console.log( billing_state, sub_total );
        let deliveryCharge = 0;

        // Logic for delivery charges
        if (billing_state && billing_state.toLowerCase() === 'delhi ncr' && sub_total >= 3000) {
            deliveryCharge = 0;
        } else if (billing_state&&billing_state.toLowerCase() === 'delhi' && sub_total < 3000) {
            deliveryCharge = 150;
        } else if (sub_total >= 5000) {
            deliveryCharge = 0;
        } else {
            deliveryCharge = 200;
        }

        return deliveryCharge;
    };
    const deliveryCharge = calculateDeliveryCharge();
    const totalPayable = totalPay + deliveryCharge;
        const saved = shippingDetails.sub_total - totalPay ;
    const renderTotalPayableAmount = () => {
        const deliveryCharge = calculateDeliveryCharge();
        // const totalPayable = shippingDetails.sub_total + deliveryCharge;
        return (
            <div className="total-payable">
                <h3>Total Payable Amount</h3>
                <p>₹{totalPayable}</p>
            </div>
        );
    };

    const renderProgressBar = () => {
        return (
            <div className="progress-bar">
                <div className={`progress ${activeStep >= 2 ? 'active' : ''}`}><span className={`checkbox-icon ${activeStep >= 2 ? 'active' : ''}`}>✓</span>Customer Details</div>
                <div className={`progress ${activeStep >= 3 ? 'active' : ''}`}><span className={`checkbox-icon ${activeStep >= 3 ? 'active' : ''}`}>✓</span>Shipping & Payment</div>
                <div className={`progress ${activeStep >= 4 ? 'active' : ''}`}><span className={`checkbox-icon ${activeStep >= 4? 'active' : ''}`}>✓</span>Order Confirmation</div>
            </div>
        );
    };
    
    const handleNextStep = () => {
        setActiveStep(prevStep => prevStep + 1);
    };

    const renderProductDetails = () => {
        return (
            <div className="order-confirmation-container">
                <div className="order-summary">
                    <h2>Order Summary</h2>
                    {orderDetails.map((product, index) => (
                        <div key={index} className="product-details">
                            <img src={product.productImg} alt={product.title} />
                            <div className="product-info">
                                <h3>{product.title}</h3>
                                <p>Weight: {product.weight}g</p>
                                <p>Price: ₹{product.price/product.quantity}</p>
                                <p>Quantity: {product.quantity}</p>
                                <p>Subtotal: ₹{product.price  }</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="order-confirmation-main">

            <Navbar />
            <div className='toplogo'>
            <img src={logo} alt="logo" className='img0' />
                </div>
            <div className="mainflex">
               
            <div className="order-confirmation-container">
                
                {renderProgressBar()}
                <div className="checkout-content">
                    <div className={`checkout-section ${activeStep === 1 ? 'active' : ''}`}>
                        <form className="shipping-form">
                        <h3>Customer Details</h3>
                            <div className='customerdetails'>
                            
                            <div>
                            <label>
                                First Name:
                                <input type="text" name="billing_customer_name" value={shippingDetails.billing_customer_name} onChange={handleChange} />
                                {errors.billing_customer_name && <p className="error">{errors.billing_customer_name}</p>}
                            </label>
                            </div>
                            <div>
                            <label>
                                Last Name:
                                <input type="text" name="billing_last_name" value={shippingDetails.billing_last_name} onChange={handleChange} />
                                {errors.billing_last_name && <p className="error">{errors.billing_last_name}</p>}
                            </label>
                            </div>
                            </div>

                            <h3>Shipping Details</h3>
                            <label>
                                Address:
                                <input type="text" name="billing_address" value={shippingDetails.billing_address} onChange={handleChange} />
                                {errors.billing_address && <p className="error">{errors.billing_address}</p>}
                            </label>
                            <div className='customerdetails'>
                            <label>
                                City:
                                <input type="text" name="billing_city" value={shippingDetails.billing_city} onChange={handleChange} />
                                {errors.billing_city && <p className="error">{errors.billing_city}</p>}
                            </label>
                            <label>
                                Pincode:
                                <input type="text" name="billing_pincode" value={shippingDetails.billing_pincode} onChange={handleChange} />
                                {errors.billing_pincode && <p className="error">{errors.billing_pincode}</p>}
                            </label>
                            </div>
                            <label>
                                State:
                                <input type="text" name="billing_state" value={shippingDetails.billing_state} onChange={handleChange} />
                                {errors.billing_state && <p className="error">{errors.billing_state}</p>}
                            </label>
                            <div className='customerdetails'>
                            <label>
                                Email:
                                <input type="email" name="billing_email" value={shippingDetails.billing_email} onChange={handleChange} />
                                {errors.billing_email && <p className="error">{errors.billing_email}</p>}
                            </label>
                            <label>
                                Phone:
                                <input type="text" name="billing_phone" value={shippingDetails.billing_phone} onChange={handleChange} />
                                {errors.billing_phone && <p className="error">{errors.billing_phone}</p>}
                            </label>
                            </div>
                            <button type="button" className="next-btn" onClick={handleNextStep}>Next</button>
                        </form>
                    </div>
                    <div className={`checkout-section ${activeStep === 2 ? 'active' : ''}`}>
                        <h3>Shipping & Payment</h3>
                        <div className="shipping-address-details">
                        <p>Customer Name: {shippingDetails.billing_customer_name}</p>
                            <p>product cost: {shippingDetails.sub_total}</p>
                            <p>Shipping Address: {shippingDetails.billing_address}</p>
                              <p>City:{shippingDetails.billing_city}</p> 
                           <p> state:     {  shippingDetails.billing_state}</p> 
                           <p>  country:    {  shippingDetails.billing_country}</p> 
                           <p>   pincode:{ shippingDetails.billing_pincode}</p> 
                           <p>  email:{  shippingDetails.billing_email}</p> 
                           <p>    phone:  {  shippingDetails.billing_phone}</p> 
                           </div>
                        <div className='delivery-charges'>
                        {renderDeliveryCharges()}
                        {renderTotalPayableAmount()}
                        </div>
                       
                            <button type="button" className="pay-btn" onClick={handlePayment}>Pay Now</button>
                    
                    </div>
                    
                    
                </div>
                   
      
            </div>
        <div className='sideflex'>
        <h2>
        YOUR ORDER
        </h2>

        {renderProductDetails()}
        <hr></hr>
        <div className='totalprice'>
         <div className='p1'>
            <p1>Subtotal</p1>
            <p2> ₹{shippingDetails.sub_total}/-</p2>
         </div>
         <div className='p2'>
            <p1>delivery charges</p1>
            <p2>₹{deliveryCharge}/-</p2>
           
         </div>
         <hr></hr>
         <div className='totalorder'>
         <p1>total price </p1>
         <p2>₹{shippingDetails.sub_total+deliveryCharge}/-</p2>
         </div>
         <hr></hr>
         <div className="coupon-code">
                                    <h3>Have a coupon?</h3>
                                   <div className='coupon_class'>
                                   <input
                                        type="text"
                                        placeholder="Enter coupon code"
                                        value={couponCode}
                                        onChange={(e) => setCouponCode(e.target.value)}
                                    />
                                    <button type="button" onClick={handleApplyCoupon}>
                                        Apply Coupon
                                    </button>
                                   </div>
                                </div>
                                <hr></hr>
                                         {saved > 0 && (
  <h2 className='saved'>
    Congratulation! You saved <span>₹{saved}/-</span> by <span>{couponCode}</span> coupon
  </h2>
)}
         <div className='totalorder'>
         <p1>total price after discount</p1>
         <p2>₹{totalPayable}/-</p2>
         </div>
        </div>
       </div>
       </div>


            <Footer />
        </div>
    );
};

export default OrderConfirmation;
