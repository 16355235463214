
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from '../src/pages/Login'
import Register from '../src/pages/Register'
import Cart from './pages/Cart'
import ShopPage from './pages/ShopPage'
import ProductDetail from './Component/ProductDetail';
import FakePaymentGateway from './Component/FakePaymentGateway';
import PaymentComplete from './pages/PaymentComplete';
import My_Order from './pages/MyOrders';
import ContactUs from './pages/ContactUs'
import Aboutus from './pages/About_us'
import AuthProvider from './Component/AuthContext';
import Forgotpass from './pages/ForgotPassword'
import { CartProvider }from './Component/CartContext';
import RegistrationSuccess from './pages/RegistrationSuccess';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Google_call from './pages/GoogleCallback'
import Reset_pass from './pages/ResetPasswordPage';
import OrderConfirmation from './pages/OrderConfirmation';
import PaymentPage from './pages/PaymentPage';
import ShippingConfirmation from './pages/ShippingConfirmation';
import OrderCompletion from './pages/OrderCompletion';
import OrderSuccessPage from './pages/OrderSuccessPage';
import Policy from './pages/PolicyOfTajalli';
import WhatsAppChatModal from './Component/Whatsappchatmodal';



function App() {
  const clientId = '71325916233-gcremu85ig2luvqog89g70htb4vgm4do.apps.googleusercontent.com';
  return (
    <div className="App">
     
   
     <GoogleOAuthProvider clientId={clientId}>
    <Router>
      <div>
     
      <AuthProvider>
       <CartProvider>
        <Routes>
        
          <Route exact path="/"  element={<Home/>} />
          {/* <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services/>} />
          <Route path="/Career" element={<Career />} /> */}
          <Route path="/Shop" element={<ShopPage/>} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/product/:productId" element={<ProductDetail />} />
          <Route path="/fake-payment-gateway" element={<FakePaymentGateway />} />
          <Route path="/payment-complete" element={<PaymentComplete />} />
          <Route path="/My-Order" element={<My_Order />} />
          <Route path="/Contactus" element={<ContactUs />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/forgotpassword" element={<Forgotpass />} />
          <Route path="/RegistrationSuccess" element={<RegistrationSuccess/>} />
          <Route path="/google-callback" element={<Google_call/>} />
          <Route path="/reset-password/:token" element={<Reset_pass/>} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/shipping-confirmation" element={<ShippingConfirmation />} />
        <Route path="/order-completion" element={<OrderCompletion />} />
        <Route path="/order-success" element={<OrderSuccessPage />} />
        <Route path="/Policy" element={<Policy/>} />


         
         
        </Routes>
       <a href="https://wa.me/918826069897" target="_blank" rel="noopener noreferrer">
        <WhatsAppChatModal/></a>
        </CartProvider>
       </AuthProvider>
     
      </div>
      
    </Router> 
    </GoogleOAuthProvider>
   
    </div> )
}

export default App;
