import React, { useState, useEffect } from 'react';
import Navbar from '../Component/Navbar';
import Slider from '../Component/Slider';
import Slider1 from '../Component/Slider1';
import Card from '../Component/Card_shop';
import Offer from '../Component/Offer';
import Testimonial from '../Component/Testimonial';
import Footer from '../Component/Footer';
import Withus from '../Component/Withus';
import { useNavigate } from 'react-router-dom';
import TawkTo from '../Component/Tawk';
import Branches from '../Component/Branches'
import Feature from '../Component/feature';
import Imageslider1 from '../Component/ImageSlider'

import axios from 'axios';
import './Home.css';
import WhatsAppChatModal from '../Component/Whatsappchatmodal';
const Home = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [raisin, setRaisin] = useState([]);
  const [berries, setBerries] = useState([]);
  const [almonds, setAlmonds] = useState([]);
  const [bestseller, setBestseller] = useState([]);
  const [items, setItems] = useState([]);
  const [error, setError] = useState('');

  const handleCategoryClick = (category) => {
    navigate('/Shop', { state: { selectedCategory: category } });
  };

const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/product/allproduct`);
        setProducts(response.data);
        setError('');
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to fetch products');
      }
    };

    fetchProducts();
    fetchRaisin();
    fetchBerries();
    fetchItems();
    fetchAlmonds();
  }, []);


   const fetchRaisin = async () => {
        
        setError('');
        try {
            let response;
           
                response = await axios.get(`${apiUrl}/api/product/search`, {
                    params: { query:'Raisin' }
                });
                if (response.data.products.length > 0) {
                    setRaisin(response.data.products);
                    setError('');
                } else {
                    setRaisin([]);
                    setError('No products found');
                }
            } 
           catch (error) {
            console.error('Error fetching products:', error);
            setError('Error fetching products');
        } 
    };
    const fetchBerries = async () => {
        
      setError('');
      try {
          let response;
         
              response = await axios.get(`${apiUrl}/api/product/search`, {
                  params: { query:'Berries' }
              });
              if (response.data.products.length > 0) {
                  setBerries(response.data.products);
                  setError('');
              } else {
                  setBerries([]);
                  setError('No products found');
              }
          } 
         catch (error) {
          console.error('Error fetching products:', error);
          setError('Error fetching products');
      } 
  };
  const fetchAlmonds = async () => {
        
    setError('');
    try {
        let response;
       
            response = await axios.get(`${apiUrl}/api/product/search`, {
                params: { query:'Almonds' }
            });
            if (response.data.products.length > 0) {
                setAlmonds(response.data.products);
                setError('');
            } else {
                setAlmonds([]);
                setError('No products found');
            }
        } 
       catch (error) {
        console.error('Error fetching products:', error);
        setError('Error fetching products');
    } 
};
const fetchItems = async () => {
        
  setError('');
  try {
      let response;
     
          response = await axios.get(`${apiUrl}/api/product/search`, {
              params: { query:'Special Item' }
          });
          if (response.data.products.length > 0) {
              setItems(response.data.products);
              setError('');
          } else {
              setItems([]);
              setError('No products found');
          }
      } 
     catch (error) {
      console.error('Error fetching products:', error);
      setError('Error fetching products');
  } 
};
const fetchBestSeller = async () => {
        
  setError('');
  try {
      let response;
     
          response = await axios.get(`${apiUrl}/api/product/search`, {
              params: { query:'Best-Seller' }
          });
          if (response.data.products.length > 0) {
              setBestseller(response.data.products);
              setError('');
          } else {
              setBestseller([]);
              setError('No products found');
          }
      } 
     catch (error) {
      console.error('Error fetching products:', error);
      setError('Error fetching products');
  } 
};
  // Limit to two rows of products (assuming 4 products per row)
  const limitedProducts = products.slice(0, 12);
  const limitedRaisin = raisin.slice(0, 6);
  const limitedAlmonds = almonds.slice(0, 6);
  const limitedBerries = berries.slice(0, 6);
  const limitedItems = items.slice(0, 6);
 
  const handleCategoryClick2 = (category) => {
    navigate('/Shop', { state: { selectedCategory: category } });
};

  return (
    <div className='home'>
      <Navbar />
      <section className='first'>
        <section><Slider /></section>
        <section className='nth'><Slider1 /></section>
        <section className="second">
          <div className="top">
          <div>  <h1>Best Seller <hr /></h1></div>
           <div> <button><a href="/Shop">View all</a></button></div>
          </div>
          <div className="delicacies">
            {error ? (
              <p>{error}</p>
            ) : (
              limitedProducts.map((product) => (
                <Card key={product.id} product={product} />
              ))
            )}
          </div>
        </section>
        <section className="second2">
          <div className="top">
          <div>  <h1>Best in Almonds <hr /></h1></div>
           <div> <button  onClick={() => handleCategoryClick2("Almonds")}
           ><a>View all</a></button></div>
          </div>
          <div className="delicacies">
            {error ? (
              <p>{error}</p>
            ) : (
              limitedAlmonds.map((raisin) => (
                <Card key={raisin.id} product={raisin} />
              ))
            )}
          </div>
        </section>
        
        <section className='third'>
          <div className="top">
          <div>  <h1>Exciting Offers<hr /></h1></div>
          <div> <button><a href="/Shop">View all</a></button></div>
          </div>
          <div className='offer_home'>
            <Offer />
          </div>
        </section>
        <section className="second3">
          <div className="top">
          <div>  <h1>Best in Raisins <hr /></h1></div>
          <div> <button  onClick={() => handleCategoryClick2("Raisins")}
           ><a>View all</a></button></div>
          </div>
          <div className="delicacies">
            {error ? (
              <p>{error}</p>
            ) : (
              limitedRaisin.map((raisin) => (
                <Card key={raisin.id} product={raisin} />
              ))
            )}
          </div>
        </section>
       
        <section className="second4">
          <div className="top">
          <div>  <h1>Best in Berries <hr /></h1></div>
          <div> <button  onClick={() => handleCategoryClick2("Berries")}
           ><a>View all</a></button></div>
          </div>
          <div className="delicacies">
            {error ? (
              <p>{error}</p>
            ) : (
              limitedBerries.map((raisin) => (
                <Card key={raisin.id} product={raisin} />
              ))
            )}
          </div>
        </section>
        <section className='fourth'>
          <Testimonial />
        </section>
        
        <section className="second5">
          <div className="top">
          <div>  <h1>Special Items <hr /></h1></div>
          <div> <button  onClick={() => handleCategoryClick2("Special-Items")}
           ><a>View all</a></button></div>
          </div>
          <div className="delicacies">
            {error ? (
              <p>{error}</p>
            ) : (
              limitedItems.map((raisin) => (
                <Card key={raisin.id} product={raisin} />
              ))
            )}
          </div>
        </section>
        <section><Feature /></section>
        <section className='Availableon'>
          <Branches />
        </section>
        <section>
          <Imageslider1/>
        </section>
        <section className='withus_home'>
          <Withus />
        </section>
        <section className='fifth'>
        </section>
          <a href="https://wa.me/8826069897" target="_blank" rel="noopener noreferrer">
        <WhatsAppChatModal/></a>
      </section>
      {/* <TawkTo /> */}
      {/* Add the WhatsApp chat modal */}
      
      <Footer />
    </div>
  );
};

export default Home;

