// ProductDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCart } from './CartContext';
import './ProductDetails.css'; // Import the CSS file
import Navbar from './Navbar';
import Footer from './Footer';
import Card from './Card_shop';

const ProductDetail = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedWeight, setSelectedWeight] = useState(1);
    const [selectedImage, setSelectedImage] = useState('');
    const { addToCart } = useCart();
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
     const [popupType, setPopupType] = useState(null); 
    const navigate = useNavigate();
     const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/product/${productId}`);
                setProduct(response.data);
                setSelectedWeight(response.data.weights[0]);
                setSelectedImage(response.data.images[0]);
                console.log('Product Weights:', product.weights);
                console.log(product);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchProduct();
    }, [productId]);

    useEffect(() => {
        if (product) {
            fetchRelatedProducts(product.category, page);
        }
    }, [product, page]);

    // related products list function
    const fetchRelatedProducts = async (category, page) => {
        try {
            const response = await axios.get(`${baseURL}/api/product/allproduct`, {
                params: {
                    category,
                    limit: 5,
                    page
                }
            });
            console.log(response);
            const currentProductIds = new Set(relatedProducts.map(product => product._id));
            const newProducts = response.data.filter(product => product._id !== productId && !currentProductIds.has(product._id));
            setRelatedProducts(prevProducts => [...prevProducts, ...newProducts]);
            setTotal(response.data.total);
        } catch (error) {
            console.error('Error fetching related products:', error);
        }
    };

    const handleShowMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleWeightChange = (event) => {
        const weight = parseInt(event.target.value, 10);
        if (!isNaN(weight)) {
            setSelectedWeight(weight);
            console.log('Selected Weight:', weight); // Debugging line
        } else {    
            console.error('Invalid weight selected:', event.target.value);
        }
    };
    const handleQuantityChange = (event) => {
        setSelectedQuantity(event.target.value);
       
    };

    const calculatedPrice = Math.round(product ? (product.price * selectedWeight / 1000) : 0);    
    const cartItemId = `${productId}${selectedWeight}`;

    const handleAddToCart = () => {
           const token = localStorage.getItem('token');

        if (!token) {
          setPopupType('login');
          return;
        }

        const orderDetails = {
            cartId: cartItemId,
            productId: product._id,
            title: product.title,
            image: product.images[0],
            category: product.category,
            weight: selectedWeight,
            price: calculatedPrice,
            totalPrice: calculatedPrice,
            quantity: selectedQuantity, // Default quantity
        };
        addToCart(orderDetails);
           setPopupType('success');
        setTimeout(() => setPopupType(null), 2000); 
    };

    const handleBuyNow = () => {
            const token = localStorage.getItem('token');

        if (!token) {
          setPopupType('login');
          return;
        }

        const orderDetails = [{
            cartId: cartItemId,
            productImg: product.images[0],
            productId: product._id,
            title: product.title,
            category: product.category,
            weight: selectedWeight,
            quantity: selectedQuantity,
            totalPrice: calculatedPrice*selectedQuantity,
            price: calculatedPrice*selectedQuantity,
        }];
        console.log(orderDetails);
        navigate('/order-confirmation', { state: { orderDetails } });
    };

    const toggleMoreDetails = () => {
        setShowMoreDetails(!showMoreDetails);
    };

    if (!product) {
        return <div>Loading...</div>;
    }
     const closePopup = () => setPopupType(null);

    const goToLogin = () => {
      navigate('/login');
      closePopup();
    };


    return (
        <div className='productdetails_main'>
            <Navbar />
            <div className="product-detail">
                <div className="image-gallery">
                    
                    <div className="focused-image">
                        <img src={selectedImage} alt={product.name} />
                    </div>
                    <div className="thumbnails">
                        {product.images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={product.name}
                                className={image === selectedImage ? 'focused' : ''}
                                onClick={() => setSelectedImage(image)}
                            />
                        ))}
                    </div>
                </div>
                <div className="product-info">
                    <h1>{product.title}</h1>
                    <div className="ratings">
                        <span>★★★★★</span> <span>(120)</span>
                    </div>
                    <p className="price">₹{calculatedPrice}/-</p>
                    
                    <div className="weight-selection">
                       <p>SIZE</p>
                        <select value={selectedWeight} onChange={handleWeightChange}>
                            {product.weights.map((weight, index) => (
                                <option key={index} value={weight}>
                                    {weight}g
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="quantity-selection">
                        <label>Select Quantity:</label>
                        <select value={selectedQuantity} onChange={handleQuantityChange}>
                            {[...Array(10).keys()].map(num => (
                                <option key={num + 1} value={num + 1}>
                                    {num + 1}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="buttons">
                        <button className="add-to-cart" onClick={handleAddToCart}>Add to Cart</button>
                        <button className="buy-now" onClick={handleBuyNow}>Buy Now</button>
                    </div>
                    <div className="description">
                        <h2>Description</h2>
                        <ul>
                            {product.aboutProduct.map((detail, index) => (
                                <li key={index}>{detail}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                
            </div>
            <div className='product-INFO'>
                <div className="product-details">
                    <div className='product_info_header'>
                        <h2>Product Information</h2>
                       <p>{product.aboutProduct[0]}</p> 
                    </div>
                    <button className="more-details-button" onClick={toggleMoreDetails}>
                        {showMoreDetails ? 'Show Less Details' : 'More Details'}
                    </button>
                </div>
                {showMoreDetails && (
                    <div className="more-details">
                        <div className='desc'>
                            <h3>Country of Origin: {product.details.origin}</h3>
                            <p>Added Preservatives: {product.details.addedPreservatives}</p>
                            <p>FSSAI Approved: {product.details.fssaiApproved}</p>
                            <p>Veg/Non-Veg: {product.details.vegNonVeg}</p>
                        </div>
                        <div className='desc'>
                            <h3>Usage Details</h3>
                            <p>Place Of Storage & Temperature: {product.details.storage}</p>
                            <p>Allergen: {product.details.allergen}</p>
                        </div>
                        <div className='desc'>
                            <h3>Product Specifications</h3>
                            <p>Net Quantity: {product.details.netQuantity}</p>
                        </div>
                        <div className='desc'>
                            <h4>Item Dimensions</h4>
                            <p>Length: {product.details.length}</p>
                            <p>width: {product.details.width}</p>
                            <p>Height: {product.details.height}</p>
                           
                        </div>
                    </div>
                )}
            </div>
            <div className='related-products'>
                <h2>You May Also Like</h2>
                <div className="product-list">
                    {relatedProducts.map((relatedProduct, index) => (
                        <Card key={relatedProduct._id} product={relatedProduct} />
                    ))}
                </div>
                {relatedProducts.length < total && (
                    <button className="show-more" onClick={handleShowMore}>Show More</button>
                )}
                 {/* Popup for logged-in users */}
      {popupType === 'success' && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Item added to cart!</p>
            <button className="popup-close" onClick={closePopup}>×</button>
          </div>
        </div>
      )}
      {/* Popup for not logged-in users */}
      {popupType === 'login' && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Please login or signup to add products to your cart.</p>
            <button className="popup-button" onClick={goToLogin}>Go to Login</button>
            <button className="popup-close" onClick={closePopup}>×</button>
          </div>
        </div>
      )}

            </div>
            <Footer />
        </div>
    );
};

export default ProductDetail;

 
