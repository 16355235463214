import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const GoogleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    console.log('Received code:', code); // Check if code is correctly received

    // Get API URL from environment variable
    const apiUrl = process.env.REACT_APP_API_URL;

    // Send code to backend to exchange for access token and fetch user info
    if (code) {
      const fetchAccessToken = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/otp/googlelogin`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code }),
          });

          if (response.ok) {
            const result = await response.json();
            console.log('Google login successful:', result);
            navigate('/RegistrationSuccess');
          } else {
            const error = await response.json();
            console.error('Google login failed:', error);
            // Handle error
          }
        } catch (error) {
          console.error('Error:', error);
          // Handle error
        }
      };

      fetchAccessToken();
    } else {
      console.error('Authorization code not found.');
      // Handle error
    }
  }, [location.search, navigate]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default GoogleCallback;
