import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import AuthProvider from './Component/AuthContext';
import { CartProvider } from './Component/CartContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      
    
     
    
    <App />
   
      
 
    
  </React.StrictMode>
);


reportWebVitals();
