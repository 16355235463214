import React, { createContext, useReducer, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';

const CartContext = createContext();

const cartReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET_CART':
      return action.payload || [];
    case 'ADD_TO_CART':
      const existingItemIndex = state.findIndex(item =>
        item.productId === action.payload.productId &&
        item.weight === action.payload.weight
      );
      if (existingItemIndex !== -1) {
        const newState = [...state];
        newState[existingItemIndex].quantity += action.payload.quantity;
        return newState;
      } else {
        return [...state, action.payload];
      }
    case 'REMOVE_FROM_CART':
      return state.filter(item =>
        !(item.productId === action.payload.productId &&
        item.weight === action.payload.weight));
    case 'UPDATE_QUANTITY': {
      const { productId, weight, quantity } = action.payload;
      return state.map(item => {
        if (item.productId === productId && item.weight === weight) {
          return { ...item, quantity };
        }
        return item;
      });
    }
    case 'CLEAR_CART':
      return []; // Clear entire cart
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, []);
  const { authState } = useAuth();

  useEffect(() => {
    const fetchCart = async () => {
      if (authState.isAuthenticated) {
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/cart`, {
            headers: {
              Authorization: `Bearer ${authState.token}`,
            },
          });
          dispatch({ type: 'SET_CART', payload: data });
        } catch (error) {
          console.error('Failed to fetch cart', error);
        }
      }
    };

    fetchCart();
  }, [authState]);

  const addToCart = async (product) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/cart/add`, product, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      dispatch({ type: 'ADD_TO_CART', payload: data });
      dispatch({ type: 'SET_CART', payload: data });
    } catch (error) {
      console.error('Failed to add to cart', error);
    }
  };

  const removeFromCart = async (productId, weight) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/user/cart/remove`, { productId, weight }, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      dispatch({ type: 'REMOVE_FROM_CART', payload: { productId, weight } });
    } catch (error) {
      console.error('Failed to remove from cart', error);
    }
  };

  const updateQuantity = async (productId, weight, quantity) => {
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/cart/update`, { productId, weight, quantity }, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      dispatch({ type: 'UPDATE_QUANTITY', payload: { productId, weight, quantity } });
    } catch (error) {
      console.error('Failed to update cart quantity', error);
    }
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateQuantity, dispatch, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
