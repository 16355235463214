import React from 'react';
import Slider from 'react-slick';
import './Slider1.css';
import imageUrls from '../Data/ImgUrls';
import { NextArrow, PrevArrow } from './CustomArrows';
import { useNavigate } from 'react-router-dom';


const SimpleSlider = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
      navigate('/Shop', { state: { selectedCategory: category } });
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
     nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
       autoplay: false,          // Enable auto-scroll
    autoplaySpeed: 3000  ,    // Set auto-scroll speed (in milliseconds)

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          arrows: false
        }
      }
    ]
  };

  return (
    <div className="slider-container1">
      <Slider {...settings} className="custom-slick-track">
        {imageUrls.map((image) => (
          
            <img src={image.imageUrl} alt={'slide-1'}
            key={image.category} 
            className="category-item" 
            onClick={() => handleCategoryClick(image.category)} />
            
         
        ))}
      </Slider>
    </div>
  );
};

export default SimpleSlider;
