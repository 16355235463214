// OrderCompletion.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './OrderCompletion.css';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';

const OrderCompletion = () => {
  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate('/');
  };

  return (
    <div className="order-completion-main">
      <Navbar />
      <div className="order-completion-container">
        <h2>Order Successful!</h2>
        <p>Your order has been placed successfully. You will receive a confirmation email shortly.</p>
        <button onClick={handleGoToHome}>Go to Home</button>
      </div>
      <Footer />
    </div>
  );
};

export default OrderCompletion;
