// src/Component/Withus.js
import React, { useState } from "react";
import axios from "axios";
import Lottie from 'react-lottie';
import animationData from '../Data/withus.json'; // Import your Lottie animation data
import './Withus.css';
import { TiSocialLinkedin } from "react-icons/ti";
import { IoLogoInstagram } from "react-icons/io";
import { TiSocialTwitter } from "react-icons/ti";
import { CiFacebook } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";

function Withus() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }};

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/email/submit-email`, { email });
      setMessage(response.data);
      setEmail(''); // Clear the input field
    } catch (error) {
      setMessage('Error submitting email');
    }
    
  };
  

  return (
    <div className="withus">
      <h2 className="heading">CONNECT WITH US</h2>
      <section className="main-section">
        <div className="left">
          {/* Replace the image with Lottie animation */}
          <Lottie options={defaultOptions} height={'100%'} width={'100%'} />
        </div>

        <div className="right">
         
            <h2>Subscribe to our newsletter</h2>
        
          <div className="subscribe-container">
            <input
              className="email-input"
              type="email"
              placeholder="Your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button className="subscribe-button" onClick={handleSubmit}>
              Subscribe
            </button>
          </div>
          {message && <p>{message}</p>}
          <div className="iconss">
            <a href="https://www.instagram.com/tajallidryfruits/" target="blank"><IoLogoInstagram /></a>
            <a href="https://www.facebook.com/profile.php?id=100087492111902" target="blank"><CiFacebook /></a>
            <a href="https://x.com/TajalliProduct" target="blank"><TiSocialTwitter /></a>
            <a href="https://www.linkedin.com/in/tajalli-product-71a740305" target="blank"><TiSocialLinkedin /></a>
            <a href="mailto:tajalliproduct@gmail.com" target="blank"><HiOutlineMail /></a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Withus;
