// SwiperSlider.js
import React, { useState, useEffect } from 'react';
import './Branches.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import axios from 'axios';
import 'swiper/css/navigation';
import { Pagination } from 'swiper/modules';
import { Navigation} from 'swiper/modules';
import Test from '../assets/Test.jpg'
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';




const SwiperSlider = () => {
  const [offers, setOffers] = useState([]);
     const baseURL = process.env.REACT_APP_API_URL;
  const slides = [
    {
      id: 1,
      imageUrl: Test,
      link: '#',
    },
    {
      id: 2,
      imageUrl: Test,
      link: '#',
    },
    {
      id: 3,
      imageUrl: Test,
      link: '#',
    },
  ];
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/offer1/`);
        setOffers(response.data);
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };
  
    
  
  
    fetchOffers();
  
  }, []);

  return (
    <div className="swiper-container3">
    <Swiper
      modules={[Navigation, Pagination]}
      // modules={[Pagination]}
      pagination={{ clickable: true }}
      Navigation
      spaceBetween={50}
      slidesPerView={1}
      loop={true}  // Enable infinite scrolling
    >
      {offers.map((slide) => (
        <SwiperSlide key={slide.id}>
          <div className='swipe' style={{ padding: '20px', textAlign: 'center' }}>
            <img src={slide.image} alt={`Slide ${slide.id}`} style={{ width: '100%', height: 'auto' }} />
            <button>
            <Link to={`/product/${slide.productId._id}`}  style={{ textDecoration: 'none' , border:'none', color:'white'}}>Learn more</Link>
              
              <FaArrowRight className='arr' />

            </button>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    </div>
  );
};

export default SwiperSlider;
