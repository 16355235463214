// src/Component/InfoSection.js

import React from 'react';
import { FaShippingFast, FaMoneyCheckAlt, FaCheckCircle, FaTrophy } from 'react-icons/fa';
import './feature.css';

const InfoSection = () => {
  const infoItems = [
    {
      icon: "//www.nutraj.com/cdn/shop/files/Cash_on_delivery.gif?v=1699438377",
      text: 'Free Shipping On Orders Above ₹3000'
    },
    {
      icon:"//www.nutraj.com/cdn/shop/files/Pay_on_delivery.gif?v=1699438447",
      text: 'Pay On Delivery'
    },
    {
      icon: "//www.nutraj.com/cdn/shop/files/100_quality.gif?v=1699438485",
      text: '100% Quality Guaranteed'
    },
    {
      icon: "//www.nutraj.com/cdn/shop/files/Award.gif?v=1699438343",
      text: 'Reward Points On Every Purchase'
    }
  ];

  return (
   <div className='info-section_main'>
    <h2> Feature That Makes Us Different</h2>
     <div className="info-section">
        
        {infoItems.map((item, index) => (
          <React.Fragment key={index}>
            <div className="info-item">
              <div className="info-icon"><img src={item.icon}></img></div>
              <div className="info-text">{item.text}</div>
            </div>
            {index < infoItems.length - 1 && <div className="info-divider"></div>}
          </React.Fragment>
        ))}
      </div>
   </div>
  );
};

export default InfoSection;
