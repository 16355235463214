import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const PaymentPage = () => {
  const location = useLocation();
  const { shippingDetails } = location.state;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handlePayment = async () => {
    try {
      const amountInPaisa = Math.max(shippingDetails.sub_total , 100); // Ensure minimum amount is 1 INR (100 paisa)
      
      const response = await axios.post(`${apiUrl}/api/payment/createOrder`, {
        amount: amountInPaisa,
        currency: 'INR',
        name: 'Product Name', // Replace with actual product name
        description: 'Product Description' // Replace with actual product description
      });

      const { order_id, currency, amount } = response.data.order;
      console.log(amount);


      const options = {
        key: "rzp_test_CXYmdJXmuXsLjV", // Replace with your Razorpay key ID
        amount: amount,
        currency: currency,
        order_id: order_id,
        handler: function (response) {
          // Handle payment success
          alert(`Payment successful. Payment ID: ${response.razorpay_payment_id}`);
          // Redirect or update UI as needed
        },
        prefill: {
          name: shippingDetails.billing_customer_name,
          email: shippingDetails.billing_email,
          contact: shippingDetails.billing_phone
        },
        theme: {
          color: '#F37254'
        }
      };

      if (window.Razorpay) {
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      } else {
        throw new Error('Razorpay SDK not loaded');
      }
    } catch (error) {
      console.error('Error during payment:', error.message);
    }
  };

  return (
    <div className="payment-page-main">
      <h2>Complete Your Payment</h2>
      <button onClick={handlePayment}>Pay Now</button>
    </div>
  );
};

export default PaymentPage;
