import React from 'react';
import '../Component/Testimonial.css';
import test1 from '../assets/test1.png';
import aamir from '../assets/testimonial_img/aamir.jpeg'
import amit from '../assets/testimonial_img/amit.jpeg'
import dheeraj_lalit from '../assets/testimonial_img/dheeraj_lalit.jpeg'
import himmat from '../assets/testimonial_img/himmat.jpeg'
import kiran from '../assets/testimonial_img/kiran.jpeg'
import lalit from '../assets/testimonial_img/lalit.jpeg'
import mewara from '../assets/testimonial_img/mewara.jpeg'
import monika from '../assets/testimonial_img/monika.jpeg'
import stars from '../assets/stars.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

const SwiperSlider = () => {
  const slides = [
    {
      id: 1,
      name: "Rajesh Gupta",
      imageUrl: mewara,
      comment:"I ordered almonds and walnuts for my family, and they arrived super fast! The packaging was really nice and secure. Definitely gonna order again!"
    },
    {
      id: 2,
      name: "Sunita Patel",
      imageUrl: kiran,
      comment:"The cashews I ordered were so fresh, I couldn't resist eating them all in one go! Great service, will recommend to my friends for sure."
    }, {
      id: 3,
      name: "Amit Kumar",
      imageUrl: amit,
      comment:"Got my order of pistachios and raisins. Initially, there was some confusion with the delivery date, but the customer support team sorted it out quickly. Happy with the products!"
    }, {
      id: 4,
      name: "Priya Sharma",
      imageUrl: monika,
      comment:"I loved the variety of products available on the website. My order of dates and apricots was delivered on time, although the packaging could have been a bit more eco-friendly"
    }, {
      id: 5,
      name: "Sanjay Singh",
      imageUrl: himmat,
      comment:"The website was easy to navigate, and I found exactly what I was looking for - quality almonds and figs. The checkout process was smooth,"
    }, {
      id: 6,
      name: "Dheeraj mourya",
      imageUrl: dheeraj_lalit,
      comment:"The dried fruits were delicious! However, there was a small delay in delivery, probably due to the festive season rush. Nonetheless, I'm happy with my purchase."
    }, {
      id: 7,
      name: "Vikram Reddy",
      imageUrl: lalit,
      comment:"Ordered some mixed dry fruits for gifting purposes. The recipient loved them! I had a bit of trouble applying the discount code, but customer service helped me out quickly."
    }, {
      id: 8,
      name: "Aamir suhail",
      imageUrl: aamir,
      comment:"The quality of the products was excellent, especially the figs and cashews. It took a little longer than expected for the order to arrive, but it was worth the wait."
    },
  ];

  return (
    <div className='container1'>
      <div className='container2'>
        <h2>What our Clients say about us</h2>
        <hr />
      </div>
      <div className="swiper-container">
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{ clickable: true }}
          spaceBetween={50}
          loop={true}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1080: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          className="mySwiper"
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className='testimonial' style={{ padding: '20px', textAlign: 'center' }}>
                <img src={slide.imageUrl} alt={`Slide ${slide.id}`} />
                <h2>{slide.name}</h2>
                <p>{slide.comment}</p>
                <img className='rating' src={stars} alt="Rating" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SwiperSlider;

