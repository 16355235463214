import React from 'react';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import { useCart } from '../Component/CartContext';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import emptyCartAnimation from '../Data/emptykart.json'; // Adjust the path as necessary
import './Cart.css';

const Cart = () => {
  const { cart, removeFromCart, updateQuantity } = useCart();
  const navigate = useNavigate();

  if (!Array.isArray(cart)) {
    return <p>Loading...</p>; // Placeholder for loading state
  }

  const handleRemove = (productId, weight) => {
    removeFromCart(productId, weight);
  };

  const handleIncrease = (productId, weight, quantity) => {
    updateQuantity(productId, weight, quantity + 1);
  };

  const handleDecrease = (productId, weight, quantity) => {
    if (quantity > 1) {
      updateQuantity(productId, weight, quantity - 1);
    }
  };

  const handleProceedToPayment = () => {
    const orderDetails = cart.map(item => ({
      cartId: item.cartId,
      productImg: item.image,
      productId: item.productId,
      title: item.title,
      category: item.category,
      weight: item.weight,
      quantity: item.quantity,
      price: item.totalPrice * item.quantity,
    }));

    navigate('/order-confirmation', { state: { orderDetails } });
  };

  const totalPrice = cart.reduce((acc, item) => acc + item.totalPrice * item.quantity, 0);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyCartAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className='cart_main'>
      <Navbar />
      <div className="cart1">
        <h1>Your Cart</h1>
        {cart.length > 0 && (
          <div className='prod-head'>
            <p>Product</p>
            <p>Category</p>
            <p>Weight</p>
            <p>Price</p>
            <p>Quantity</p>
            <p>ADD/Remove</p>
          </div>
        )}

        {cart.length === 0 ? (
          <div className="empty-cart">
            <Lottie options={defaultOptions} height={'30%'} width={'30%'}/>
            <p>Your cart is empty.</p>
          </div>
        ) : (
          cart.map(item => (
            <div key={item.cartId} className="cart-item">
              <div className='prod-det'>
                <img src={item.image} alt={item.title} className="cart-item-image" />
                <h3>{item.title}</h3>
              </div>
              <div className="cart-item-details">
                <p className='category_cart'>{item.category}</p>
                <p>{item.weight} g</p>
                <p>₹{item.totalPrice}</p>
                <div className="quantity-controls">
                  <button onClick={() => handleDecrease(item.productId, item.weight, item.quantity)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => handleIncrease(item.productId, item.weight, item.quantity)}>+</button>
                </div>
                <button onClick={() => handleRemove(item.productId, item.weight)}>Remove</button>
              </div>
            </div>
          ))
        )}
        <div className='proceed'>
          <h2>Total: ₹{totalPrice}</h2>
          {cart.length > 0 && (
            <button className="proceed-btn" onClick={handleProceedToPayment}>
              Proceed to Payment
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
