import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import { GoogleLogin } from '@react-oauth/google';
import './Register.css';

const Registration = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    password: '',
    confirmPassword: '',
    mob: '',
    otp: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        console.log('Fetched country data:', data);
    
        const countryList = data.map(country => {
          const idd = country.idd || {};
          const callingCode = idd.root ? idd.root + (idd.suffixes ? idd.suffixes[0] || '' : '') : '';
    
          return {
            name: country.name.common,
            code: country.cca2,
            callingCode
          };
        });
    countryList.sort((a, b) => a.name.localeCompare(b.name));
        setCountries(countryList);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendOtp = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/otp/sendOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: formData.mob }),
      });

      if (response.ok) {
        setOtpSent(true);
        setOtpError('');
      } else {
        const error = await response.json();
        setOtpError(error.error);
        alert(error.error);
      }
    } catch (error) {
      setOtpError('Failed to send OTP. Please try again.');
      alert('Failed to send OTP. Please try again.');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/otp/verifyOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: formData.mob, otp: formData.otp }),
      });

      if (response.ok) {
        setOtpVerified(true);
        setOtpError('');
      } else {
        const error = await response.json();
        setOtpError(error.error);
        alert(error.error);
      }
    } catch (error) {
      setOtpError('Failed to verify OTP. Please try again.');
      alert('Failed to verify OTP. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setSubmitError('Passwords do not match.');
      alert('Passwords do not match.');
      return;
    }
    // if (!otpVerified) {
    //   setSubmitError('Please verify your mobile number.');
    //   alert('Please verify your mobile number.');
    //   return;
    // }

    const dataToSend = {
      firstname: formData.fname,
      lastname: formData.lname,
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      mobile: selectedCountryCode + formData.mob,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Registration successful:', result);
        navigate('/RegistrationSuccess');
      } else {
        const error = await response.json();
        console.error('Registration failed:', error);
        setSubmitError(error.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitError('Registration failed. Please try again.');
    }
  };

  const handleGoogleLogin = () => {
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/auth?client_id=71325916233-gcremu85ig2luvqog89g70htb4vgm4do.apps.googleusercontent.com&redirect_uri=http://localhost:3000/google-callback&response_type=code&scope=email%20profile&access_type=offline`;

    window.location.href = googleAuthUrl;
  };

  return (
    <div className='register_main'>
      <Navbar />
      <div className="registration-container">
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username"></label>
            <div className='register_name'>
              <input
                type="text"
                id="fname"
                name="fname"
                placeholder='First Name'
                value={formData.fname}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                id="lname"
                name="lname"
                placeholder='Last Name'
                value={formData.lname}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="countryCode">Country Code</label>
            <select
              id="countryCode"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Country Code</option>
              {countries.map(country => (
                <option key={country.code} value={country.callingCode}>
                  {country.name} ({country.callingCode})
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="Mobile no."></label>
            <input
              type="tel"
              id="mob"
              name="mob"
              placeholder='Mobile NO.'
              value={formData.mob}
              onChange={handleChange}
              required
            />
            {/* <button type="button" onClick={handleSendOtp}>Verify No.</button>
            {otpSent && !otpVerified && (
              <div>
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder='Enter OTP'
                  value={formData.otp}
                  onChange={handleChange}
                  required
                />
                <button type="button" onClick={handleVerifyOtp}>Verify OTP</button>
              </div>
            )}
            {otpError && <p className="error">{otpError}</p>} */}
          </div>
          <div className="form-group">
            <label htmlFor="email"></label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder='E-Mail'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password"></label>
            <div className="password-container">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
                required
              />
              <span
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword"></label>
            <div className="password-container">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                placeholder='Confirm Password'
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              <span
                className="password-toggle"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>
          {submitError && <p className="error">{submitError}</p>}
          <button type="submit" >Register</button>
        </form>
        <div className="links">
          <Link to="/Login"><span> have an account? </span>Log In</Link>
        </div>
        <button onClick={handleGoogleLogin} className="google-signin-button">Sign in with Google</button>
      </div>
      <Footer />
    </div>
  );
};

export default Registration;




