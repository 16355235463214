import React, { useState, useEffect } from 'react';
import Sidebar from '../Component/Sidebar';
import ProductList from '../Component/ProductList';
import './ShopPage.css';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaBars, FaTimes } from 'react-icons/fa';
import Lottie from 'react-lottie';
import * as errorAnimationData from '../Data/noproduct.json'; // Import your Lottie animation file

const ShopPage = () => {
    const location = useLocation();
    const [selectedCategory, setSelectedCategory] = useState('All Products');
    const [products, setProducts] = useState([]);
    const [fetchedCategories, setFetchedCategories] = useState([]);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const searchQuery = location.state?.query || null;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: errorAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        if (location.state?.selectedCategory) {
            setSelectedCategory(location.state.selectedCategory);
        }
    }, [location.state]);

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            setError('');
            try {
                let response;
                if (searchQuery !== null && searchQuery !== '') {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/search`, {
                        params: { query: searchQuery }
                    });
                    if (response.data.products.length > 0) {
                        setProducts(response.data.products);
                        setError('');
                    } else {
                        setProducts([]);
                        setError('No products found');
                    }
                } else {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/allproduct`);
                    setProducts(response.data);
                }
                
            } catch (error) {
                console.error('Error fetching products:', error);
                setError('Error fetching products');
            } finally {
                setIsLoading(false);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/`);
                setFetchedCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchProducts();
        fetchCategories();
    }, [searchQuery]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setIsSidebarVisible(false);
    };

    const filteredProducts = selectedCategory === 'All Products'
        ? products
        : products.filter(product => product.category === selectedCategory);

    return (
        <div className='shop_main'>
            <Navbar />
            <button className="categories-button" onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
                Categories
            </button>
            <div className={`shop-page ${isSidebarVisible ? 'sidebar-visible' : ''}`}>
                <Sidebar 
                    categories={fetchedCategories} 
                    onSelectCategory={handleCategorySelect} 
                    selectedCategory={selectedCategory}
                    isVisible={isSidebarVisible}
                    onClose={() => setIsSidebarVisible(false)}
                />
                {isLoading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <div className="error-container">
                        <Lottie options={defaultOptions} height={'50%'} width={'50%'} />
                        <p>{error}</p>
                    </div>
                ) : (
                    <ProductList products={filteredProducts} />
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ShopPage;
