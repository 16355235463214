import React from "react";
import './Footer.css';
import Mastercard from '../assets/mastercard.png';
import razorpay from '../assets/razorpay.png';
import upi from '../assets/upi.png';
import { FaEnvelope } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { IoLogoInstagram } from "react-icons/io";
import { CiTwitter } from "react-icons/ci";
import { CiFacebook } from "react-icons/ci";
import { MdWhatsapp } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import imageUrls from '../Data/logo_img';

function Footer1() {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate('/Shop', { state: { selectedCategory: category } });
  };

  const handlePageClick = (page) => {
    navigate(page);
  };

  return (
    <div className="footer_pro">
      <div className="body1">
        <section className="main">
          <section className="header">
            <section className="container">
              <div className="footer-section">
                <h2>Shop</h2>
                <ul>
                  <li onClick={() => handleCategoryClick('Pistachio')}>Pistachio</li>
                  <li onClick={() => handleCategoryClick('Almonds')}>Almonds</li>
                  <li onClick={() => handleCategoryClick('Cashew')}>Cashew</li>
                  <li onClick={() => handleCategoryClick('Dates')}>Dates</li>
                  <li onClick={() => handleCategoryClick('Berries')}>Berries</li>
                </ul>
              </div>

              <div className="footer-section">
                <h3>Learn</h3>
                <ul>
                  <li onClick={() => handlePageClick('/')}>Home</li>
                  <li onClick={() => handlePageClick('/AboutUs')}>About Us</li>
                  <li onClick={() => handlePageClick('/ContactUs')}>Contact Us</li>
                </ul>
              </div>

              <div className="footer-section">
                <h3>More from us</h3>
                <ul>
                  <li onClick={() => handleCategoryClick('Pistachio')}>Pistachio</li>
                  <li onClick={() => handleCategoryClick('Almonds')}>Almonds</li>
                  <li onClick={() => handleCategoryClick('Cashew')}>Cashew</li>
                  <li onClick={() => handleCategoryClick('Dates')}>Dates</li>
                  <li onClick={() => handleCategoryClick('Berries')}>Berries</li>
                </ul>
              </div>
            </section>
            <p>We Accept the following Payment Methods</p>
            <div className="payment-methods">
              <img src={Mastercard} alt="MasterCard" />
              {/* <img src={razorpay} alt="Razorpay" />
              <img src={upi} alt="UPI" /> */}
              <img src='https://static-00.iconduck.com/assets.00/visa-icon-2048x1313-o6hi8q5l.png' alt="UPI" />
              <img src='https://i0.wp.com/www.dafontfree.io/wp-content/uploads/2023/11/amex-logo.png?resize=2000%2C1311&ssl=1' alt="UPI" />
              <img src='https://www.shareicon.net/download/2016/09/14/829013_card_512x512.png' alt="UPI" />
              <img src='https://seeklogo.com/images/J/jcb-logo-442F407E4A-seeklogo.com.png' alt="UPI" />
              <img src='https://logos-world.net/wp-content/uploads/2023/02/Diners-Club-International-Logo.png' alt="UPI" />
              <img src='https://1000logos.net/wp-content/uploads/2021/05/Discover-logo.png' alt="UPI" />
              <img src='https://logos-world.net/wp-content/uploads/2020/08/PayPal-Logo.jpg' alt="UPI" />
            </div>
          </section>

          <section className="connect">
            <h2>Let's stay connected</h2>
            <p className="connect_p">Enter Your Email To Unlock Offers</p>

            <div className="lines">
              <div className="input-box">
                <input type="email" placeholder="Your Email" />
                <button className="btn">Submit</button>
              </div>
              <hr />
            </div>
            <div className="alsoavail">
              <h2>Also Available on</h2>
            <div className='company_sub1'>
        {imageUrls.map((image) => (
          <div className='company_logo1' title={image.name}>
            <img src={image.imageUrl} alt={image.name}
            key={image.category} 
            className="category-item11" 
            />
            
         </div>
        ))}
        </div>
        </div>
          
            <div className="social-media">
              <h2>Follow Us</h2>
              <div className="icons">
                <a href="https://www.instagram.com/tajallidryfruits/" target="_blank" rel="noopener noreferrer"><IoLogoInstagram /></a>
                <a href="https://www.facebook.com/profile.php?id=100087492111902" target="_blank" rel="noopener noreferrer"><CiFacebook /></a>
                <a href="https://x.com/TajalliProduct" target="_blank" rel="noopener noreferrer"><CiTwitter /></a>
                <a href="mailto:tajalliproduct@gmail.com" target="_blank" rel="noopener noreferrer"><CiMail /></a>
                <a href="https://wa.me/918826069897" target="_blank" rel="noopener noreferrer"><MdWhatsapp /></a>
              </div>
            </div>

            <p className="policy_footer">
              <span onClick={() => handlePageClick('/Policy')} style={{ cursor: 'pointer', color: "white" }}>
                Terms of services | Privacy Policy | Refund Policy | Accessibility Policy
              </span>
            </p>
             
              <p className="policy_footer">
              <span style={{ cursor: 'pointer', color: "white" }}>
                Developed By Team Avalon Web Services
              </span>
            </p>
           
          </section>
        </section>
      </div>
    </div>
  );
}

export default Footer1;

