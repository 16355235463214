import React, { useState, useContext, useEffect, useRef } from 'react';
import './Navbar.css';
import logo from '../assets/logo.png';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useCart } from '../Component/CartContext';
import { FaSistrix, FaShoppingCart } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { PiArrowBendRightDownLight } from "react-icons/pi";
import dropdown from '../assets/dropdown.png';
import { AuthContext } from '../Component/AuthContext';
import axios from 'axios';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const {cart, addToCart } = useCart();
  const navbarRef = useRef(null);
  const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const [profileImage, setProfileImage] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); // New state for scroll

  useEffect(() => {
    if (authState.isAuthenticated) {
      fetchUserDetails();
    }
  }, [authState.isAuthenticated]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem('token'); // or use context if stored there

      if (!token) {
        alert('Authentication token not found. Please log in again.');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/getuser`, config); // Change the endpoint to match your backend
      setProfileImage(response.data.profileImage);
    } catch (err) {
      console.error('Error fetching user details:', err);
    }
  };

 const handleCategoryClick = (category) => {
    navigate('/Shop', { state: { selectedCategory: category } });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if(query!==''){
    navigate('/Shop', { state: { query } });}
   
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container" ref={navbarRef}>
      <Link to="/"  alt='mylogo' id='res_logo'  className='mylogo'>
          <img src={logo} alt="logo" className='logo'/>
        </Link>
     <div className='main_navi'>
     <div className={`navbar-toggle ${isOpen ? '' : 'active'}`} onClick={toggleMenu}>
            <span className="navbar-toggle-icon"></span>
            <span className="navbar-toggle-icon"></span>
            <span className="navbar-toggle-icon"></span>
          </div>
      <Link to="/"  className={`mylogo ${isScrolled ? 'hidden' : ''}`}>
          <img src={logo} alt="My logo"  className={`logo ${isScrolled ? 'hidden' : ''}`}/>
        </Link>
        <div   className={`search45 ${isScrolled ? '' : 'hidden'}`}>
            <div className="search-box">
              <FaSistrix className="search-icon" />
              <input
                type="text"
                className="search-input"
                placeholder="Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
               <button className="search-button" onClick={handleSearch}>Search</button>
            </div>
           
          </div>
        <div className='navi'>
        <div className='iconsmain'>
            <Link to='/Cart'>
              <FaShoppingCart className="cart" />
              {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
            </Link>
            {authState.isAuthenticated ? (
              <NavLink to="/Login" className={`profile-picture2 ${isScrolled ? 'hidden' : ''}`}>
                <img src={profileImage} alt="Profile" className="profile-picture" />
              </NavLink>
            ) : (
              <Link to="/Login" className={`profile-picture2 ${isScrolled ? 'hidden' : ''}`}><MdAccountCircle className="account" /></Link>
            )}
          </div>
      </div>
     </div>
      <div   className={`search45 ${isScrolled ? 'hidden' : ''}`}>
            <div className="search-box">
              <FaSistrix className="search-icon" />
              <input
                type="text"
                className="search-input"
                placeholder="Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
               <button className="search-button" onClick={handleSearch}>Search</button>
            </div>
           
          </div>
        <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
          <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
            <li className='close_li'><button id='close' onClick={handleClose}><IoCloseSharp /></button></li>
            <li><NavLink to="/">Home</NavLink></li>
            <li className="dropdown">
              <NavLink to="/Shop">Shop <PiArrowBendRightDownLight /></NavLink>
              <div className="dropdown-content">
                <div className='right'>
                  <li onClick={() => handleCategoryClick('Dates')}>Dates</li>
                  <li onClick={() => handleCategoryClick('Berries')}>Berries</li>
                  <li onClick={() => handleCategoryClick('Nuts')}>Nuts</li>
                  <li onClick={() => handleCategoryClick('Stuffed Dates')}>Stuffed Dates</li>
                  <li onClick={() => handleCategoryClick('All Products')}>All Products</li>
                </div>
                <div className='middle'>
                  <li onClick={() => handleCategoryClick('Dry Fruits')}>Dry Fruits</li>
                  <li onClick={() => handleCategoryClick('Cashew')}>Cashew</li>
                  <li onClick={() => handleCategoryClick('Seeds')}>Seeds</li>
                  <li onClick={() => handleCategoryClick('Pistachio')}>Pistachio</li>
                </div>
                <img src={dropdown} />
              </div>
            </li>
            <li><NavLink to="/Aboutus">About Us</NavLink></li>
            <li><NavLink to="/Contactus">Contact Us</NavLink></li>
            <li><NavLink to="/My-Order">My Orders</NavLink></li>
            <li className='close_li'><NavLink to="/Cart"><FaShoppingCart className="cart_nav" /></NavLink></li>
            <li className='close_li'><NavLink to="/Login"><MdAccountCircle className="account1" /></NavLink></li>
          </ul>
        </div>
        <div className='main-div'>
          <div className='search'>
            <div className="search-box">
              <FaSistrix className="search-icon" />
              <input
                type="text"
                className="search-input"
                placeholder="Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <button className="search-button" onClick={handleSearch}>Search</button>
          </div>
          <div className='iconsmain'>
            <Link to='/Cart'>
              <FaShoppingCart className="cart" />
              {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
            </Link>
            {authState.isAuthenticated ? (
              <NavLink to="/Login">
                <img src={profileImage} alt="Profile" className="profile-picture" />
              </NavLink>
            ) : (
              <Link to="/Login"><MdAccountCircle className="account" /></Link>
            )}
          </div>
        </div>
        </div>
        <div className='res_search_ham'>
          <div className='search_res'>
            <div className="search-box_res">
           
              {isSearchOpen && (
                <div className="search-overlay">
                  <div className="search-box-overlay-search-box2">
                    <FaSistrix className="search-icon2" onClick={handleSearch} />
                    <input
                      type="text"
                      className="search-input2"
                      placeholder="Search..."
                      value={query}
                      onChange={handleQueryChange}
                    />
                    <IoCloseSharp className="close-icon2" onClick={toggleSearch} />
                  </div>
                </div>
              )}
            </div>
          </div>
         
        </div>
     
    </nav>
  );
};

export default Navbar;

