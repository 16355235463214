// src/components/PaymentComplete.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import './PaymentComplete.css';  // Create this CSS file for styling
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';

const PaymentComplete = () => {
    const location = useLocation();
    const order = location.state?.order || {};

    return (
        <div className='payment-complete_main'>
            <Navbar />
            <div className="payment-complete">
                <h1>Payment Complete</h1>
                <div className="order-summary">
                    <h2>Order Summary</h2>
                    <p><strong>Order Number:</strong> {order.orderNumber || 'N/A'}</p>
                    <p><strong>Product:</strong> {order.product?.name || 'N/A'}</p>
                    <p><strong>Quantity:</strong> {order.quantity || 'N/A'}</p>
                    <p><strong>Total Price:</strong> ₹{order.price || 'N/A'}</p>
                    <p><strong>Status:</strong> {order.status || 'N/A'}</p>
                </div>
                <div className="tracking-info">
                    <h3>Track Your Order</h3>
                    <p>You can track your order using the order number above.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PaymentComplete;
