import React from 'react';
import '../Component/Card1.css';
import off from '../assets/off.png';
import { Link } from 'react-router-dom';

const Card1 = ({ img,discountPercent,productId }) => {
  return (
    <div className='offer1'>
      <div className='image-container'>
        <img src={off} alt="Discount" id='off' />
        <div className='overlay-text'><span className='per_off'>{discountPercent}%</span> <br></br>Off</div>
        <img src={img} alt="Product" id='straw' />
      </div>
      <button><Link to={`/product/${productId}`}  style={{ textDecoration: 'none' , border:'none', color:'white'}}>ORDER NOW</Link></button>
    </div>
  );
}

export default Card1;
