import React from 'react';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import left from "../assets/left.png";
import right from "../assets/right.png";
import Lottie from 'react-lottie'; // Updated import
import animationData from '../Data/contact_ani.json'; // Replace with your actual animation file path
import loaderAnimation from '../Data/loader.json'; // Add loader animation file path
import './ContactUs.css';

const ContactUs = () => {
  const [result, setResult] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "14c0d073-75fc-4513-a39e-e5460d1601ce");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });

      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    } catch (error) {
      console.error("Submission error:", error);
      setResult("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="contact_us_main">
      <Navbar />
      <div className="contact_us">
        <section className="main1">
          <img src={left} alt="" />
          <h1>Contact Us </h1>
          <img src={right} alt="" />
        </section>

        <p className="here"><b>We would love to hear from you</b></p>
        <hr />

        <section className="contact-container">
          <div className="header-contact">
            <Lottie options={defaultOptions} height={'100%'} width={'100%'}  />
          </div>
          <div className="contact-content">
            {loading ? (
              <div className="loading-container">
                <Lottie options={loaderOptions} height={'100%'} width={'100%'}  />
                <p>{result}</p>
              </div>
            ) : (
              <form className="contact-form" onSubmit={onSubmit}>
                <div className="input-group">
                  <input type="text" name="fname" placeholder="First name" required />
                  <input type="text" name="lname" placeholder="Last name" required />
                </div>
                <div className="input-group">
                  <input type="tel" name="mobile" placeholder="Phone" required />
                  <input type="email" name="email" placeholder="E-mail" required />
                </div>
                <input type="text" name="subject" placeholder="Subject" required />
                <textarea name="message" placeholder="Your message here" required></textarea>
                <button type="submit">SUBMIT</button>
              </form>
            )}
            {!loading && result && <p className="result-message">{result}</p>}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
