import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ShippingConfirmation = () => {
  const location = useLocation();
  const { orderDetails } = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    const createShippingOrder = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/shiprocket/create-order`, { orderDetails });
        // Handle shipping order response
        console.log('Shipping order created:', response.data);
      } catch (error) {
        console.error('Error creating shipping order:', error);
      }
    };

    createShippingOrder();
  }, [orderDetails]);

  return (
    <div className='shipping-confirmation'>
      <h2>Shipping Confirmation</h2>
      <p>Your order has been placed successfully.</p>
      <p>Tracking details will be sent to your email.</p>
      <button onClick={() => navigate('/order-completion')}>Complete Order</button>
    </div>
  );
};

export default ShippingConfirmation;
