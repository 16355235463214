import React, { useState } from 'react';
import './whatsapp.css';

const WhatsAppChatModal = ({ phoneNumber, message }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div>
      <button className="whatsapp-chat-button" onClick={openModal}>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"
          alt="WhatsApp Chat"
          style={{ width: '50px', height: '50px' }}
        />
      </button>

    </div>
  );
};

export default WhatsAppChatModal;
