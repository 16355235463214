// ImageUrls.js
import amazon from '../assets/amazon.png'
import flipkart from '../assets/flipkart.png'
import myntra from '../assets/myntra.png'
import JioMart from '../assets/JioMart.png'
import INDIAMART from '../assets/INDIAMART.png'

const ImageUrls = [
    {
        name: 'Amazon',
        imageUrl: amazon,
        imageref:'https://www.amazon.in/s?k=TAJALLI&ref=bl_dp_s_web_0',
    },
    {
        name: 'Flipkart',
        imageUrl: flipkart,
        imageref:'https://www.amazon.in/s?k=TAJALLI&ref=bl_dp_s_web_0',
    },
    {
        name: 'Myntra',
        imageUrl: myntra,
        imageref:'https://www.amazon.in/s?k=TAJALLI&ref=bl_dp_s_web_0',
    },
    {
        name: 'Jio Mart',
        imageUrl: JioMart,
        imageref:'https://www.amazon.in/s?k=TAJALLI&ref=bl_dp_s_web_0',
    },
    {
        name: 'India Mart',
        imageUrl: INDIAMART,
        imageref:'https://www.amazon.in/s?k=TAJALLI&ref=bl_dp_s_web_0',
    },
];

export default ImageUrls;

