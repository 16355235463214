import React from 'react';
import Slider from 'react-slick';
import './ImageSlider.css';
import imageUrls from '../Data/logo_img';
import { NextArrow, PrevArrow } from './CustomArrows';
import { useNavigate } from 'react-router-dom';


const SimpleSlider = () => {
  const navigate = useNavigate();

  
  
  return (
    <div className="company_main">
   <h2>ALSO AVAILABLE ON</h2>
        <div className='company_sub'>
        {imageUrls.map((image) => (
          <div className='company_logo' title={image.name}>
          <a href={image.imageref} target='blank'>
          <img src={image.imageUrl} alt={image.name}
            key={image.category} 
            className="category-item1" 
            />
          </a>
            
         </div>
        ))}
        </div>
   
    </div>
  );
};

export default SimpleSlider;

