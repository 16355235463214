import { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import Card1 from './Card1';
import "../Component/Offer.css";

function Offer() {
  const [offers, setOffers] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Use the environment variable for the API URL
    const apiUrl = process.env.REACT_APP_API_URL;
    
    axios.get(`${apiUrl}/api/offer/`)
      .then(response => {
        setOffers(response.data);
      })
      .catch(error => {
        console.error("Error fetching offers:", error);
      });
  }, []);

  const settings = {
    infinite: true,
    lazyLoad: true,
    arrows: false,
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          centerPadding: "0",
          centerMode: false,
          beforeChange: (current, next) => setImageIndex(next-1),
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          beforeChange: (current, next) => setImageIndex(next-1),
          centerPadding: "0px",
           dots: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
           dots: true,
        }
      }
    ]
  };

  const getMiddleIndex = () => {
    const middle = Math.floor(settings.slidesToShow / 2);
    return (((imageIndex + middle) % offers.length));
  };

  const middleIndex = getMiddleIndex();

  const handleOrderNow = (productId) => {
    navigate(`/product/${productId}`);
  };
  const defaultProductId = 'default-id';

  return (
    <div className="contain">
      <div className="Wpp">
        <Slider {...settings}>
          {offers.map((offer, idx) => (
            <div key={idx} className={idx === (middleIndex) ? "slide activeSlide" : "slide"}>
              <Card1 
                img={offer.image} 
                discountPercent={offer.discountPercentage} 
                 productId={offer.productId?._id || defaultProductId}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Offer;
