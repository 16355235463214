import React from 'react';
import Card from './Card_shop'
import { Link } from 'react-router-dom';

const ProductList = ({ products }) => {
    return (
        <div className="product-list">
            
            {products.map(product => (
                
             <Card key={product.id} product={product} />
              
            ))}
        </div>
    );
};

export default ProductList;
