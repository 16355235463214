import React, { useState, useEffect } from 'react';
import OrderDetails from '../Component/OrderDetails';
import './MyOrders.css';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ConfirmationModal from '../Component/ConfirmationModal';
import Lottie from 'react-lottie';
import noOrdersAnimation from '../Data/noorders.json'; // Adjust the path as necessary

const MyOrders = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [orderToCancel, setOrderToCancel] = useState(null);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getorders`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch orders');
            }

            const data = await response.json();
      
            setOrders(data.orders);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    const openModal = (order) => {
        setSelectedOrder(order);
    };

    const closeModal = () => {
        setSelectedOrder(null);
    };

    const openConfirmation = (orderId) => {
        setOrderToCancel(orderId);
        setIsConfirmationOpen(true);
    };

    const closeConfirmation = () => {
        setOrderToCancel(null);
        setIsConfirmationOpen(false);
    };

    const confirmCancelOrder = async () => {
        if (!orderToCancel) return;

        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/payment/orders/${orderToCancel}/cancel`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status !== 200) {
                throw new Error('Failed to cancel order');
            }

            // Refetch orders after cancellation
            fetchOrders();
            closeConfirmation();
        } catch (error) {
            console.error('Error canceling order:', error);
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: noOrdersAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className='myorders-main'>
            <Navbar />
            <div className="my-orders">
                <h1>My Orders</h1>
                <div className="order-list">
                    {orders.length > 0 ? (
                        orders.map(order => (
                           <div className="order-card" key={order.order_id}>
                             <div className='order-card2'>
                              { order.orderItems.map(orderItems => ( 
                                <div className='block2' key={orderItems.productId}>
                                    <Link to={`/product/${orderItems.productId}`}>
                                        <img 
                                            src={orderItems.productImg || 'default-image-url'} 
                                            alt="Product" 
                                            className="product-image" 
                                        />
                                    </Link>
                                    <div className='block1'>
                                        <Link to={`/product/${orderItems.productId}`}>
                                            <h2>{orderItems.productName}</h2>
                                        </Link>
                                        <p><strong>Weight:</strong> {orderItems.weight}</p>
                                    </div>
                                    <div>
                                        <p><strong>Price:</strong> ₹{orderItems.price}</p>
                                    </div>
                                    <div>
                                        <p><strong>Quantity:</strong> {orderItems.quantity}</p>
                                    </div>
                                </div>
                              ))}
                             </div>
                            <div className='order_buttons'>
                                <button onClick={() => openModal(order)}>View Details</button>
                                <button onClick={() => openConfirmation(order.order_id)}>Cancel Order</button>
                            </div>
                           </div>
                        ))
                    ) : (
                        <div className="empty-orders">
                            <p>No orders found</p>
                            <Lottie options={defaultOptions} height={300} width={300} />
                            
                        </div>
                    )}
                </div>
            </div>
            {selectedOrder && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <OrderDetails order={selectedOrder} onClose={closeModal} />
                    </div>
                </div>
            )}
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={closeConfirmation}
                onConfirm={confirmCancelOrder}
                message="Are you sure you want to cancel this order?"
            />
            <Footer />
        </div>
    );
};

export default MyOrders;
