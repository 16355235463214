// ImageUrls.js
import pista from '../assets/pista.png';
import almonds_1 from '../assets/almonds_1.png';
import cashew_1 from '../assets/cashew_1.png';
import dates_1 from '../assets/dates_1.png';
import berry_1 from '../assets/berry_1.png';

const ImageUrls = [
    {
        category: 'Pistachio',
        imageUrl: pista,
    },
    {
        category: 'Almonds',
        imageUrl: almonds_1,
    },
    {
        category: 'Cashew Nuts',
        imageUrl: cashew_1,
    },
    {
        category: 'Dates',
        imageUrl: dates_1,
    },
    {
        category: 'Berries',
        imageUrl: berry_1,
    },
];

export default ImageUrls;

