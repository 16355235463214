import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const initialAuthState = {
    isAuthenticated: false,
    user: null,
    token: null,
  };
  const [authState, setAuthState] = useState(initialAuthState);

  useEffect(() => {
    const userStr = localStorage.getItem('user');
    const token = localStorage.getItem('token');

    if (userStr && token) {
      try {
        const user = JSON.parse(userStr);
        setAuthState({
          isAuthenticated: true,
          user,
          token,
        });
      } catch (error) {
        console.error('Error parsing user data:', error);
        handleLogout(); // Clear invalid user data if parsing fails
      }
    }
  }, []);

  const login = async (userData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/login`, userData);
      const { id, firstname, lastname, email, mobile, role, token, profileImage } = response.data;

      localStorage.setItem('user', JSON.stringify({ id, firstname, lastname, email, mobile, role }));
      localStorage.setItem('profileImage', profileImage);
      localStorage.setItem('token', token);

      setAuthState({
        isAuthenticated: true,
        user: { id, firstname, lastname, email, mobile, role, profileImage },
        token,
      });

      navigate('/'); // Redirect to homepage after successful login
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setAuthState(initialAuthState);
    navigate('/login'); // Redirect to login page after logout
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setAuthState(initialAuthState);
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create and export the useAuth hook
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
