

import React from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
// import { NextArrow, PrevArrow } from './CustomArrows';
import './Slider.css';


const SimpleSlider = () => {
  const navigate = useNavigate();
  const handleShopNowClick = () => {
    navigate('/Shop');
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
     autoplay: true,          // Enable auto-scroll
     autoplaySpeed: 3000      // Set auto-scroll speed (in milliseconds)

  };

  return (
    <div className="slider-container">
      {/* <h2> Simple Slider </h2> */}
      <Slider {...settings}>
        <div className="btn2">
          <img src='https://res.cloudinary.com/dqa6jk5fx/image/upload/v1722759470/nltkiyr5lnr3uoldrpef.jpg' alt="Slide 2" />
          
        </div>
        <div className="btn2">
        <img src='https://res.cloudinary.com/dqa6jk5fx/image/upload/v1722759786/aivsoiekpkunr1jjvlww.jpg'  alt="Slide 2" />
          
        </div>
        <div className="btn2">
        <img src='https://res.cloudinary.com/dqa6jk5fx/image/upload/v1722759842/tzftgw3y9ly0k08c4nlo.jpg'  alt="Slide 2" />
        </div>
        <div className="btn2">
        <img src='https://res.cloudinary.com/dqa6jk5fx/image/upload/v1722759885/ncuxy7cxzqu97gwi3gz6.jpg'  alt="Slide 2" />
        </div>
      </Slider>
    </div>
  );
};

export default SimpleSlider;

