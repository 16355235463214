import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Login.css';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import { AuthContext } from '../Component/AuthContext';
import { useCart } from '../Component/CartContext';
import axios from 'axios';
import ConfirmationModal from '../Component/ConfirmationModal';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const { authState, login, logout } = useContext(AuthContext);
  const { clearCart } = useCart();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageOptionsVisible, setIsImageOptionsVisible] = useState(false);

  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname: '',
    address: '',
    email: '',
    profileImage: '',
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login({ email, password });
      await fetchUserDetails();
      if (authState.isAuthenticated){
        navigate('/'); // Redirect to homepage or another page after successful login
      }
    } catch (err) {
      setError('Invalid email or password. Please try again.');
    }
  };

  const handleLogout = () => {
    setIsModalOpen(true); // Show the confirmation modal
  };

  const confirmLogout = () => {
    logout();
    clearCart(); // Clear cart after logout
    setIsModalOpen(false); // Close the modal
  };

  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem('token'); // or use context if stored there

      if (!token) {
        alert('check your email and password. Please log in again.');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/getuser`, config);
      setUserDetails(response.data);
    } catch (err) {
      console.error('Error fetching user details:', err);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token'); // or use context if stored there

      if (!token) {
        alert('Authentication token not found. Please log in again.');
        return;
      }
      const formData = new FormData();
      formData.append('firstname', userDetails.firstname);
      formData.append('lastname', userDetails.lastname);
      formData.append('address', userDetails.address);
      if (userDetails.profileImage instanceof File) {
        formData.append('profileImage', userDetails.profileImage);
      }
      const updatedUserDetails = {
        ...userDetails,
        profileImage: userDetails.profileImage instanceof File ? URL.createObjectURL(userDetails.profileImage) : userDetails.profileImage
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      };
      const config2 = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };
      await axios.put(`${process.env.REACT_APP_API_URL}/api/user/edit-user`, updatedUserDetails, config);
      if (userDetails.profileImage instanceof File) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/user/edit-user-pro`, formData, config2);
      }

      await fetchUserDetails(); // Fetch the updated details
      alert('User details updated successfully.');
    } catch (err) {
      console.error('Error updating user details:', err);
      alert('Failed to update user details.');
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setUserDetails({ ...userDetails, profileImage: e.target.files[0] });
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      fetchUserDetails();
    }
  }, [authState.isAuthenticated]);

  if (authState.isAuthenticated) {
    return (
      <div className='logged-in_main'>
        <Navbar />
        <div className='logged-in-container'>
          <h2>Welcome, {userDetails.firstname}</h2>
          <p>Email: {userDetails.email}</p>
          <div className="profile-image-container">
            {userDetails.profileImage && typeof userDetails.profileImage === 'string' ? (
              <img src={userDetails.profileImage} alt="Profile" className="profile-image" />
            ) : (
              userDetails.profileImage && <img src={URL.createObjectURL(userDetails.profileImage)} alt="Profile" className="profile-image" />
            )}
            <div className="image-options">
              <div className="half left" onClick={() => window.open(userDetails.profileImage, '_blank')}>Show Image</div>
              <div className="half right">
                <label htmlFor="profileImageUpload" className="update-image-button">Update Image</label>
                <input
                  type="file"
                  id="profileImageUpload"
                  onChange={handleImageChange}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </div>
          <button onClick={handleLogout}>Logout</button>
          <h3>Update Your Details</h3>
          <form onSubmit={handleUpdate}>
            <div className="form-group">
              <label htmlFor="firstname">First Name</label>
              <input
                type="text"
                id="firstname"
                value={userDetails.firstname}
                onChange={(e) => setUserDetails({ ...userDetails, firstname: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                id="lastname"
                value={userDetails.lastname}
                onChange={(e) => setUserDetails({ ...userDetails, lastname: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                value={userDetails.address}
                onChange={(e) => setUserDetails({ ...userDetails, address: e.target.value })}
                required
              />
            </div>
            <button type="submit">Update Details</button>
          </form>
        </div>
        <Footer />
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={confirmLogout}
          message="Are you sure you want to Logout ?"
        />
      </div>
    );
  }

  return (
    <div className='login-main'>
      <Navbar />
      <div className="login-container">
        <h2>LOGIN</h2>
        <p>Please enter your login and password</p>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email"></label>
            <input
              type="email"
              id="email"
              placeholder='E-mail'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <div className='password-container'>
              <label htmlFor="password"></label>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>
          <div className="links">
            <Link to="/forgotpassword">Forgot Password?</Link>
          </div>
          <button type="submit">Login</button>
        </form>
        <div className="links">
          <Link to="/register"><span>Don’t have an account? </span>Sign Up</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
