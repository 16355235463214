// src/components/FakePaymentGateway.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from './CartContext';
import './FakePaymentGateway.css';  // Create this CSS file for styling
import Navbar from './Navbar'
import Footer from './Footer';

const FakePaymentGateway = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dispatch } = useCart();
    const orderDetails = location.state?.orderDetails || {};

    const [cardNumber, setCardNumber] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');

    const handlePaymentSuccess = () => {
        // Simulate saving the order
        const orderNumber = `ORD${Math.floor(Math.random() * 1000000)}`;
        const order = {
            orderNumber,
            ...orderDetails,
            status: 'Completed',
        };

        // Add order to local storage or context (for simplicity, using local storage here)
        const existingOrders = JSON.parse(localStorage.getItem('orders')) || [];
        existingOrders.push(order);
        localStorage.setItem('orders', JSON.stringify(existingOrders));

        // Clear the cart
        dispatch({ type: 'CLEAR_CART' });

        // Redirect to payment completion page with order details
        navigate('/payment-complete', { state: { order } });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handlePaymentSuccess();
    };

    return (
      <div className='fake-payment-gateway_main'>
          <Navbar/>
          <div className="fake-payment-gateway">
            <h1>Payment Information</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Card Number</label>
                    <input
                        type="text"
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Card Holder Name</label>
                    <input
                        type="text"
                        value={cardHolderName}
                        onChange={(e) => setCardHolderName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Expiry Date</label>
                    <input
                        type="text"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                        required
                        placeholder="MM/YY"
                    />
                </div>
                <div className="form-group">
                    <label>CVV</label>
                    <input
                        type="text"
                        value={cvv}
                        onChange={(e) => setCvv(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Complete Payment</button>
            </form>
        </div>
        <Footer/>
      </div>
    );
};

export default FakePaymentGateway;
