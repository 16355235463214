import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from './CartContext';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import './Card.css';

const Card = ({ product }) => {
 const [selectedWeight, setSelectedWeight] = useState(product.weights[0]);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [popupType, setPopupType] = useState(null); // For handling popup types
  const { addToCart } = useCart();
  const navigate = useNavigate(); // For navigation

  const handleWeightChange = (event) => {
    const weight = parseFloat(event.target.value);
    setSelectedWeight(weight);
  };

  const productImage = Array.isArray(product.images) && product.images.length > 0
    ? product.images[0]
    : '';

    const calculatedPrice = Math.round((product.price * selectedWeight) / 1000);
  const handleAddToCart = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setPopupType('login');
      return;
    }

    const cartItemId = `${product._id}${selectedWeight}`;
    const productWithDetails = {
      cartId: cartItemId,
      productId: product._id,
      title: product.title,
      image: product.images[0],
      category: product.category,
      weight: selectedWeight,
      totalPrice: calculatedPrice,
      quantity: 1,
    };
    addToCart(productWithDetails);
    setPopupType('success');
    setTimeout(() => setPopupType(null), 2000); // Auto-close after 3 seconds
  };

  const handleWishlistToggle = () => {
    alert('Add to wishlist functionality is coming soon, you can use the cart until then. Thank you!');
    setIsWishlisted(!isWishlisted);
  };

  const closePopup = () => setPopupType(null);

  const goToLogin = () => {
    navigate('/login');
    closePopup();
  };

  return (
    <div className='card_shop'>
      <Link to={`/product/${product._id}`} className='card-link1'>
        <img src={productImage} alt="Product" />
      </Link>
      <hr />
      <div className='two'>
        <div className='wishlist'>
          <Link to={`/product/${product._id}`} className='card-link1'>
            <h2>{product.title}</h2>
          </Link>
          <div className='wishlist-icon' onClick={handleWishlistToggle}>
            {isWishlisted ? <AiFillHeart /> : <AiOutlineHeart />}
          </div>
        </div>
        <h3>{product.category}</h3>
        <p className='about-product'>{product.aboutProduct}</p>
        <div className='weightperg'>
          <p>MRP: <strong>{calculatedPrice}/-</strong></p>
          <select
            className='weight'
            id={`weight-select-${product._id}`}
            value={selectedWeight}
            onChange={handleWeightChange}>
              {product.weights && product.weights.map(weight => (
              <option key={weight} value={weight}>{weight}gm</option>
            ))}
          </select>
        </div>
        <button className='add' onClick={handleAddToCart}>Add to Tajalli cart</button>
      </div>
      {/* Popup for logged-in users */}
      {popupType === 'success' && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p> Item added to cart!</p>
            <button className="popup-close" onClick={closePopup}>×</button>
          </div>
        </div>
      )}
      {/* Popup for not logged-in users */}
      {popupType === 'login' && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Please login or signup to add products to your cart.</p>
            <button className="popup-button" onClick={goToLogin}>Go to Login</button>
            <button className="popup-close" onClick={closePopup}>×</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;

